import Cookies from 'js-cookie'
import firebase from '../firebase'
import 'firebase/firebase-auth';
import router from '../router'

export default {
  state: {
    user: null,
    authRequired: false,
    authRequested: false,
    menus: [],
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
    setAuthRequired(state, payload) {
      state.authRequired = payload;
    },
    setAuthRequested(state, payload) {
      state.authRequested = payload;
    },
    setUserMenus (state, payload) {
      state.menus = payload;
    }
  },
  actions: {
    signIn ({commit}) {
      commit('setAuthRequested', true);
    },
    async fetchUserMenus({commit}) {
        const response = await fetch('/api/menus');
        commit('setUserMenus', await response.json());
    },
    autoSignIn ({commit, dispatch}, payload) {
      commit('loadingComplete')
      commit('setUser', {
        id: payload.uid,
        name: payload.displayName,
        email: payload.email,
        photoUrl: payload.photoURL
      })
      dispatch('fetchUserMenus')
    },
    logout ({commit}, payload) {
      firebase.auth().signOut()
      commit('loadingComplete', payload)
      commit('setUser', null)
      commit('setAuthRequested', false)
      commit('setUserMenus', [])
      router.push('/')
      //need redirect
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    authRequested(state) {
      return state.authRequested;
    },
    authRequired(state) {
      return state.authRequired;
    }
  },
  registered(store) {
    const auth = firebase.auth();
    store.commit('loading', 'startup')
    store.watch((state, {user, authRequested, authRequired, loading}) => {
      return {user, authRequested, authRequired, loading};
    }, ({user, authRequested, authRequired, loading}) => {
      if(!loading && !user) {
        if(authRequested || authRequired) {
          auth.signInWithRedirect(new firebase.auth.SAMLAuthProvider('saml.backoffice-for-checkwise-trusted'))
        }
      }
    });
    auth.onAuthStateChanged((user) => {
      auth.getRedirectResult().then(async (userCredentials) => {
        const activeUser = user || userCredentials.user || {};
        let {uid, displayName, photoURL, email} = activeUser;

        if (activeUser.getIdToken) {
          const token = await activeUser.getIdToken(token);
          Cookies.set('__session', token)
        }
        if (userCredentials && userCredentials.additionalUserInfo) {
          const {FirstName, LastName} = userCredentials.additionalUserInfo.profile;
          displayName = `${FirstName} ${LastName}`;
        }
        if (uid) {
            store.dispatch('autoSignIn', {
              uid, displayName, photoURL, email
            });
        }
        else {
            store.commit('loadingComplete')
        }
      }).catch(error => {
        store.dispatch('logout', error)
      });
    });
  }
}