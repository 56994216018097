import Vue from 'vue'
import Loading from './Loading'
import Error from './Error'

import store from '../store'

export default function AuthGuard (compToGuard) {
  return Vue.component('AuthGuard', {
    render(createElement) {
      if(this.loading) {
        return createElement(Loading);
      }
      else if(this.error) {
        return createElement(Error);
      }
      else if(this.user) {
        return createElement(compToGuard);
      }
      else {
        return null;
      }
    },
    computed: {
      user () {
        return this.$store.getters.user
      },
      loading() {
        return this.$store.getters.loading;
      },
      error() {
        return this.$store.getters.error;
      }
    },
    beforeRouteEnter(to, from , next) {
      store.commit('setAuthRequired', true);
      next();
    },
    beforeRouteLeave(to, from, next) {
      store.commit('setAuthRequired', false)
      next();
    }
  });
}