import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import { sync } from 'vuex-router-sync'

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

import App from './App.vue'
import router from './router'
import store from './store'


sync(store, router)

const AlertCmp = () => import('./components/Shared/Alert.vue')

Vue.config.productionTip = false

Vue.component('app-alert', AlertCmp)

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App),
  created () {
  }
}).$mount('#app')
