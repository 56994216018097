export default {
    state: {
      loading: false,
      error: null
    },
    mutations: {
      loading (state, loading) {
        state.error = null;
        state.loading = loading;
      },
      loadingComplete(state, payload) {
        state.loading = false
        state.error = payload || null;
      },
    },
    actions: {
      loading({commit}) {
        commit('loading')
      },
      loadingComplete({commit}, payload) {
        commit('loadingComplete', payload)
      }
    },
    getters: {
      loading (state) {
        return state.loading
      },
      error (state) {
        return state.error
      }
    }
  }