import firebase from 'firebase/app';
import 'firebase/firebase-analytics';

firebase.initializeApp({
  apiKey: 'AIzaSyDdkJMC5Pz_nd_brXyNP1uvgf9_FVJ51fQ',
  authDomain: 'checkwise-com.firebaseapp.com',
  databaseURL: 'https://checkwise-com.firebaseio.com',
  projectId: 'checkwise-com',
  storageBucket: 'https://checkwise-com.firebaseio.com',
  messagingSenderId: "1091814481292",
  appId: "1:1091814481292:web:46496906b3d7062d430aa9",
  measurementId: "G-N00LHTKT9X"
})
firebase.analytics();

export default firebase