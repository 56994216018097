import Vue from "vue";
import Router from "vue-router";
import AuthGuard from "./components/AuthGuard";
import RawPageRouterView from "./components/RawPageRouterView";
import NotFoundComponent from "./components/NotFound";
const Home = () => import("./components/Home");
const Profile = () => import("./components/User/Profile");
const SignIn = () => import("./components/User/SignIn");
const UserScans = () => import("./components/UserScans");
const UserScan = () => import("./components/UserScan");
const Charts = () => import("./components/Charts");

Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/user-scans",
      component: AuthGuard(RawPageRouterView),
      children: [
        {
          path: "",
          name: "User-Scans",
          component: UserScans,
        },
        {
          path: "document",
          component: RawPageRouterView,
          children: [
            {
              path: ":docId",
              name: "User-Scan",
              component: UserScan,
            },
          ],
        },
      ],
    },
    {
      path: "/profile",
      name: "Profile",
      component: AuthGuard(Profile),
    },
    {
      path: "/charts",
      name: "Charts",
      component: AuthGuard(Charts),
    },
    {
      path: "/signin",
      name: "SignIn",
      component: SignIn,
    },
    {
      path: "*",
      component: NotFoundComponent,
    },
  ],
  mode: "history",
});

router.beforeEach((to, from, next) => {
  document.title = `${to.name}`;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", "");
  next();
});

export default router;
