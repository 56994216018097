import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import shared from './shared'

Vue.use(Vuex)
const modules = {
  user,
  shared,
}

export default new Vuex.Store({
  modules,
  plugins: [(store) => {
    Object.values(modules).forEach(module => {
      module.registered && module.registered(store);
    })
  }]
})