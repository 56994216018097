import Vue from 'vue'

export default {
    state: {items: [], documents: {}},
    mutations: {
        setUserScans(state, payload) {
          state.items = payload
        },
        setUserScanDocument(state, payload) {
          Vue.set(state.documents, payload.id, payload);
        }
    },
    actions: {
      async fetchUserScanDocument({commit}, {docId}) {
        const response = await fetch(`/api/user-scan/document/${docId}`);
        commit('setUserScanDocument', await response.json());
      },
      async fetchUserScans({commit}) {
        const response = await fetch('/api/user-scan');
        commit('setUserScans', await response.json());
      },
      async autoSignIn({dispatch}) {
        await dispatch('fetchUserScans');
      }
    },
    async registered(store) {
      await store.dispatch('fetchUserScans');
    }
  }