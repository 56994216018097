<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list dense>
        <v-list-item v-for="page in pages" :key="page.link" :to="page.link">
          <v-list-item-action>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ page.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="indigo" dark >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-toolbar-title>Application</v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-btn
        v-if="!userIsAuthenticated"
        @click="onSigninGoogle">
        <v-icon left >lock_open</v-icon>
        Log-in with Google
      </v-btn>
      <v-btn
        v-if="userIsAuthenticated"
        @click="onLogout">
        <v-icon left >exit_to_app</v-icon>
        Logout

      </v-btn>
    </v-app-bar>
    <v-content>
      <router-view></router-view>
    </v-content> 
    <v-footer
      color="indigo"
      app
    >
      <span class="white--text">&copy; 2020</span>
    </v-footer>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer:null,
    }),
    computed: mapState({
      menuItems () {
        let menuItems = [
          {icon: 'lock_open', title: 'Sign in', link: '/signin'}
        ]
        if (this.userIsAuthenticated) {
          menuItems = [
            {icon: 'person', title: 'Profile', link: '/profile'}
          ]
        }
        return menuItems
      },
      pages: state => {
        return state.user && state.user.menus || []; 
      },
      userIsAuthenticated () {
        return this.$store.getters.user !== null && this.$store.getters.user !== undefined
      }
    }),
    methods: {
      onLogout () {
        this.$store.dispatch('logout')
        this.$router.push('/')
      },
      onSigninGoogle() {
        this.$store.dispatch('signIn')
      }
    }
  }
</script>
